<template>
    <div style="height:100%;">
        <v-row class="fill-height" align-content="center">
            <v-col xs="12" md="4" v-if="avatar && !avatarRight">
                <div class="text-center">
                    <v-avatar size="150">
                        <v-icon size="150" v-if="customer.img == null">mdi-account-circle</v-icon>
                        <v-img size="150" v-else :src="customer.img"></v-img>
                    </v-avatar>
                </div>
            </v-col>
            <v-col xs="12" :md8="avatar" :lg8="avatar" :xl8="avatar" :md12="!avatar" :lg12="!avatar" :xl12="!avatar">
                <slot></slot>
                <v-list dense three-line class="transparent py-0">
                    <v-list-item>
                        <v-list-item-content>
                            <div class="display-1 font-weight-light">
                                {{ customer.fullName }}
                            </div>
                            <div class="caption grey--text" v-if="customer.parent != undefined && customer.parent != null">
                                <i><b>Parent</b>: {{ customer.parent.fullName }}</i>
                            </div>
                            <v-rating v-model="customerAverage" background-color="orange lighten-3" 
                                color="orange" small readonly class="ml-0 pl-0" half-increments></v-rating>
                        </v-list-item-content>
                        <v-list-item-action v-if="edit && (customer.parent == undefined || customer.parent == null)" @click="editCustomer" style="cursor: pointer">
                            <v-icon>mdi-pencil</v-icon>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item v-if="customer.addressLine1 != undefined && customer.addressLine1.length > 0">
                        <v-list-item-content>
                            <div>{{ customer.addressLine1 }}</div>
                            <div>{{ customer.addressLine2 }}</div>
                            <div>{{ customer.addressCity }} {{ customer.addressState }}, {{ customer.addressZipcode }}</div>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="customer.parent != undefined && customer.parent.addressLine1 != undefined && customer.parent.addressLine1.length > 0">
                        <v-list-item-content>
                            <div>{{ customer.parent.addressLine1 }}</div>
                            <div>{{ customer.parent.addressLine2 }}</div>
                            <div>{{ customer.parent.addressCity }} {{ customer.parent.addressState }}, {{ customer.parent.addressZipcode }}</div>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-list dense class="transparent pt-0">
                    <v-list-item v-if="customer.phone != undefined && customer.phone.length > 0">
                        <v-list-item-content>{{ this.formatPhoneNum(customer.phone) }}</v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-phone</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="customer.parent != undefined && customer.parent.phone != undefined && customer.parent.phone.length > 0">
                        <v-list-item-content>{{ this.formatPhoneNum(customer.parent.phone) }}</v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-phone</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="customer.email != undefined && customer.email.length > 0">
                        <v-list-item-content>{{ customer.email }}</v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-email-outline</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="customer.parent != undefined && customer.parent.email != undefined && customer.parent.email.length > 0">
                        <v-list-item-content>{{ customer.parent.email }}</v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-email-outline</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="customer.otherId != undefined && customer.otherId.length > 0">
                        <v-list-item-content>{{ customer.otherId }}</v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-card-account-details-outline</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-divider inset class="mr-5 my-2 mb-3" v-if="showMeasurements"></v-divider>

                <v-row no-gutters align-content="center" :wrap="$vuetify.breakpoint.lgAndDown" v-if="showMeasurements && measurementsValid">
                    <v-col xs="4" xl="3">
                        <div class="text-center">
                            <img :src="require('../assets/images/checkout-coat.svg')" style="height:100px;" />
                        </div>
                        <div class="text-center font-weight-light">
                            {{ customer.rentalMeasurements.coatSize + customer.rentalMeasurements.coatLength }} <span class="grey--text">|</span> S: {{ customer.rentalMeasurements.coatSleeve }}
                        </div>
                    </v-col>
                    <v-col xs="4" xl="3">
                        <div class="text-center">
                            <img :src="require('../assets/images/checkout-vest.svg')" style="height:100px;" />
                        </div>
                        <div class="text-center font-weight-light">
                            S: {{ customer.rentalMeasurements.vestSize }}
                        </div>
                    </v-col>
                    <v-col xs="4" xl="3">
                        <div class="text-center">
                            <img :src="require('../assets/images/checkout-pants.svg')" style="height:100px;" />
                        </div>
                        <div class="text-center font-weight-light">
                            {{ customer.rentalMeasurements.pantWaist + customer.rentalMeasurements.pantType }} <span class="grey--text">|</span> O: {{ customer.rentalMeasurements.pantOutseam }}
                        </div>
                    </v-col>
                    <v-col xs="4" xl="3">
                        <div class="text-center">
                            <img :src="require('../assets/images/checkout-shirt.svg')" style="height:100px;" />
                        </div>
                        <div class="text-center font-weight-light">
                            S: {{ customer.rentalMeasurements.shirtSize }}
                        </div>
                    </v-col>
                    <v-col xs="4" xl="3">
                        <div class="text-center">
                            <img :src="require('../assets/images/checkout-shoes.svg')" style="height:100px;" />
                        </div>
                        <div class="text-center font-weight-light">
                            {{ customer.rentalMeasurements.shoeSize }}
                        </div>
                    </v-col>
                </v-row>
                <v-row class="fill-height">
                    <v-col cols="12">
                        <v-btn block large color="grey darken-4" dark @click="openMeasurementDialog" v-if="edit && showMeasurements && measurementsValid">Edit Measurements</v-btn>
                    </v-col>
                </v-row>

                <v-btn v-if="showMeasurements && !measurementsValid && edit" block large color="grey darken-4" 
                    dark @click="openMeasurementDialog">Add Measurements</v-btn>
            </v-col>
            <v-col xs="12" md="4" v-if="avatar && avatarRight">
                <div class="text-center">
                    <v-avatar size="150">
                        <v-icon size="150" v-if="customer.img == null">mdi-account-circle</v-icon>
                        <v-img size="150" v-else :src="customer.img"></v-img>
                    </v-avatar>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="measurementsDialog" persistent :width="$vuetify.breakpoint.lgAndUp ? '50%' : '80%'">
            <v-card>
                <v-card-title>
                    <div class="subheading grey--text">
                        {{ customer.fullName }}'s New Measurements
                    </div>
                </v-card-title>
                <v-card-text>
                    <customer-rental-measurements ref="customerRentalMeasurements" :measurements="customer.rentalMeasurements" 
                        :show-previous-measurements="getMeasurements" @saved="updateMeasurements" @error="showMeasurementsError" />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeMeasurementDialog">Cancel</v-btn>
                    <v-btn text @click="saveRentalMeasurements">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="newCustomerDialog" width="600" persistent>
            <v-card>
                <v-card-title class="mt-2">
                    <div class="subheading grey--text">
                        Customer Information
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="body-1 error--text" v-if="customerError != null">{{ customerError }}</div>
                    <customer-basic-information ref="customerBasicInformation" 
                        :customer-id="customer.id"
                        :val-strength="valStrength"
                        :phone-required="customerPhoneRequired"
                        @saved="afterCustomerSaved"
                        @error="savingCustomerError"
                        @endProcessing="customerSaving = false" />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="newCustomerDialog = false" text>Cancel</v-btn>
                    <v-btn :loading="customerSaving" @click="saveCustomer" text>Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CustomerRentalMeasurements from '@/components/CustomerRentalMeasurements.vue';
import CustomerBasicInformation from '@/components/CustomerBasicInformation.vue';
import { _st } from '@/softech';

export default {
    props: {
        customer: {
            type: Object,
            default: null,
            required: true
        },
        avatar: {
            type: Boolean,
            default: true
        },
        avatarRight: {
            type: Boolean,
            default: false
        },
        edit: {
            type: Boolean,
            default: false
        },
        showMeasurements: {
            type: Boolean,
            default: false,
        },
        valStrength: {
            type: String,
            default: 'strong'
        },
        customerPhoneRequired: { // prop that indicate if the customer phone number is required when creating a new one.
            type: Boolean, 
            default: true
        }
    },
    data() {
        return {
            measurementsDialog: false,
            coatLengthOptions: [
                { id: 'S', name: 'Short' },
                { id: 'R', name: 'Regular' },
                { id: 'L', name: 'Long' }
            ],
            getMeasurements: true,
            cleanMeasurements: false,
            newCustomerDialog: false,
            customerError: null,
            customerSaving: false,
            cbiId: null,
        }
    },
    computed: {
        measurementsValid() {
            if ( _st.isNUE( this.customer.rentalMeasurements ) || _st.isNUE( this.customer.rentalMeasurements.id ) )
                return false;
                
            return true;
        },
        customerAverage() {
            if( this.customer.d2mOrders == undefined )
                return 0;

            let score = 0;
            let d2mOrders = parseInt( this.customer.d2mOrders );
            let rentShopOrders = parseInt( this.customer.rentShopOrders );
            let memberYears = parseInt( this.customer.memberYears ); 
            let timeScore = (d2mOrders + rentShopOrders) > 0 ? memberYears * 4 : 0;

            let numerator = (d2mOrders * 5) + (rentShopOrders * 4) + timeScore;
            let denominator = d2mOrders + rentShopOrders + memberYears == 0 ? 1 : d2mOrders + rentShopOrders + memberYears;


            let ts = numerator / ( denominator * 1.00);
            score = parseFloat( parseFloat(ts).toFixed(1) );

            return isNaN( score ) ? 0 : score;
        },
    },
    watch: {
        customer() {
            this.closeMeasurementDialog();
        }
    },
    methods: {
        editCustomer() {
            this.newCustomerDialog = true;
        },
        openMeasurementDialog() {
            this.$refs.customerRentalMeasurements.resetMeasurements();
            this.measurementsDialog = true;
        },
        closeMeasurementDialog() {
            this.measurementsDialog = false;
            this.$refs.customerRentalMeasurements.resetMeasurements();
        },
        saveRentalMeasurements() {
            this.$refs.customerRentalMeasurements.save(this.customer.id);
        },
        saveCustomer() {
            if (this.$refs.customerBasicInformation.isValid()) {
                this.customerSaving = true;
                this.$refs.customerBasicInformation.save();
            }
        },
        afterCustomerSaved( customerId ) {
            this.newCustomerDialog = false;
            // console.log('afterCustomerSaved');
            this.$http
                .get( '/customer/' + customerId ) 
                .then( response => { 
                    if( response.status === 401 ) {
                        let obj = this;
                        this.$root.$el.__vue__.$once('login', function() {
                            obj.afterCustomerSaved(customerId);
                        });
                        return;
                    }

                    if( !response.data.status && response.data.error ) {
                        this.mxShowMessage( 'ERROR: ' + response.data.message, 'error' );
                    }

                    if( !response.data.status )
                        return;

                    this.$emit('customerUpdated', response.data.data);
                });
        },
        savingCustomerError( error ) {
            this.customerError = error;
            this.customerSaving = false;
        },
        updateMeasurements( m ) {
            this.measurementsDialog = false;
            this.$refs.customerRentalMeasurements.resetMeasurements();
            this.$emit('rentalMeasurementUpdated', m);
        },
        showMeasurementsError( msg ) {
            this.mxShowMessage( msg, 'error' );
        }
    },
    components: {
        CustomerRentalMeasurements,
        CustomerBasicInformation
    }
}
</script>
