<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-menu ref="useDate" v-model="useDateMenu" lazy 
                    transition="scale-transition" offset-y full-width min-width="290px"
                    :close-on-content-click="false" :disabled="disabled || readonly" :readonly="readonly">
                    <template v-slot:activator="{ on }">
                        <v-text-field :value="formattedDate" label="Use Date" prepend-icon="mdi-calendar" readonly v-on="on"
                            :error-messages="useDateError" :disabled="disabled"></v-text-field>
                    </template>
                    <v-date-picker v-model="localUseDate" no-title scrollable :min="minRentDate" @change="setUseDate" 
                        :disabled="disabled" :readonly="readonly">
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="4">
                <v-menu ref="wantDate" v-model="wantDateMenu" 
                    lazy transition="scale-transition" offset-y full-width min-width="290px"
                    :close-on-content-click="false" :disabled="disabled || readonly" :readonly="readonly">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="localWantDate" label="Pickup Date" prepend-icon="mdi-calendar" readonly v-on="on"
                            :error-messages="wantDateError" :disabled="disabled"></v-text-field>
                    </template>
                    <v-date-picker v-model="localWantDate" no-title scrollable :max="useDate" @change="wantDateMenu = false" 
                        :disabled="disabled" :readonly="readonly">
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="2" class="pr-2">
                <v-menu ref="menu" v-model="wantDateHourMenu" :close-on-content-click="false"
                    :return-value.sync="localWantDateTime" lazy transition="scale-transition" offset-y
                    full-width max-width="290px" min-width="290px" :disabled="disabled || readonly" :readonly="readonly">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="wantDateTimeFormatted" label="Pickup time" readonly v-on="on"
                            :error-messages="wantDateTimeError" :disabled="disabled"></v-text-field>
                    </template>
                    <v-time-picker v-if="wantDateHourMenu" v-model="localWantDateTime" full-width 
                        @click:minute="$refs.menu.save(localWantDateTime)" :disabled="disabled" :readonly="readonly">
                    </v-time-picker>
                </v-menu>
            </v-col>

            <v-col cols="6" class="pl-2">
                <v-menu ref="returnDate" v-model="returnDateMenu"
                    lazy transition="scale-transition" offset-y full-width min-width="290px"
                    :close-on-content-click="false" :disabled="disabled || readonly" :readonly="readonly">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="localReturnDate" label="Return Date" prepend-icon="mdi-calendar" readonly v-on="on"
                            :error-messages="returnDateError" :disabled="disabled"></v-text-field>
                    </template>
                    <v-date-picker v-model="localReturnDate" no-title scrollable :min="useDate" @change="returnDateMenu = false">
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-select v-model="localPickupLocation" :items="pickupLocations" item-text="name" item-value="id" label="Pickup Location" 
                    prepend-icon="mdi-map-marker" :error-messages="pickupLocationError" :disabled="disabled" :readonly="readonly">
                    <template v-slot:item="data">
                        <v-list-tile-content>
                            <v-list-tile-title v-html="data.item.name"></v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-action class="pt-0">
                            <div class="grey--text">{{ formatMoney(data.item.charge) }}</div>
                        </v-list-tile-action>
                    </template>
                </v-select>

                <v-select v-model="localEventRoleId" :items="memberTypes" item-text="name" item-value="id" label="Customer Type" 
                    prepend-icon="mdi-account-check" :error-messages="eventRoleError" :disabled="disabled" :readonly="readonly"></v-select>

                <v-textarea name="input-7-1" label="Reservation comments" 
                    v-model="localComments" rows="3"
                    prepend-icon="mdi-message-reply-text" :disabled="disabled" :readonly="readonly"></v-textarea>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { _st } from '@/softech';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
    props: {
        useDate             : { type: String, default: '' },
        wantDate            : { type: String, default: '' },
        wantDateTime        : { type: String, default: '' },
        returnDate          : { type: String, default: '' },
        pickupLocation      : { type: [Number, String], default: null },
        eventRoleId         : { type: [Number, String], default: null },
        comments            : { type: String, default: '' },
        storeId             : { type: [String, Number], default: 0 },

        disabled            : { type: Boolean, default: false },
        readonly            : { type: Boolean, default: false },
    },
    data() {
        return {
            useDateMenu         : false,
            wantDateMenu        : false,
            returnDateMenu      : false,
            wantDateHourMenu    : false,

            localUseDate        : this.useDate,
            localWantDate       : this.wantDate,
            localWantDateTime   : '14:00',
            localReturnDate     : this.returnDate,
            localPickupLocation : _st.isNU( this.pickupLocation ) ? this.pickupLocation : this.pickupLocation.toString(),
            localEventRoleId    : this.eventRoleId,
            localComments       : this.comments,

            useDateError        : '',
            wantDateError       : '',
            wantDateTimeError   : '',
            returnDateError     : '',
            pickupLocationError : '',
            eventRoleError      : '',

            memberTypes         : [
                { id: '3', name: 'Best Man', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '17', name: 'Bride', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '5', name: 'Brother', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '9', name: 'Child', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '12', name: 'Family Member', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '10', name: 'Father', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '2', name: 'Grandfather', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '1', name: 'Groom', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '4', name: 'Groomsmen', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '8', name: 'Guest', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '11', name: 'Master of Ceremony', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '7', name: 'Ring Bearer', eventType: 'Wedding', eventTypeId: 1, selected: false, },
                { id: '6', name: 'Uncle', eventType: 'Wedding', eventTypeId: 1, selected: false, },                

                { id: '13', name: 'Student', eventType: 'Prom', eventTypeId: 2, selected: false, },

                { id: '13', name: 'Student', eventType: 'Prom', eventTypeId: 6, selected: false, },

                { id: '13', name: 'Student', eventType: 'Prom', eventTypeId: 7, selected: false, },

                { id: '15', name: 'Gala', eventType: 'Gala', eventTypeId: 4, selected: false, },

                { id: '5', name: 'Brother', eventType: 'Quinceañera', eventTypeId: 3, selected: false, },
                { id: '9', name: 'Child', eventType: 'Quinceañera', eventTypeId: 3, selected: false, },
                { id: '12', name: 'Family Member', eventType: 'Quinceañera', eventTypeId: 3, selected: false, },
                { id: '10', name: 'Father', eventType: 'Quinceañera', eventTypeId: 3, selected: false, },
                { id: '8', name: 'Guest', eventType: 'Quinceañera', eventTypeId: 3, selected: false, },
                { id: '11', name: 'Master of Ceremony', eventType: 'Quinceañera', eventTypeId: 3, selected: false, },
                { id: '6', name: 'Uncle', eventType: 'Quinceañera', eventTypeId: 3, selected: false, },
            ],
        }
    },
    watch: {
        useDate( v )            { this.localUseDate = v; },
        wantDate( v )           { this.localWantDate = v; },
        returnDate( v )         { this.localReturnDate = v; },
        pickupLocation( v )     { this.localPickupLocation = v; },
        eventRoleId( v )        { this.localEventRoleId = v; },
        comments( v )           { this.localComments = v; },

        localUseDate()          { this.change() },
        localWantDate()         { this.change() },
        localReturnDate()       { this.change() },
        localPickupLocation()   { this.change() },
        localEventRoleId()      { this.change() },
        localComments()         { this.change() },
    },
    computed: {
        ...mapGetters({
            pickupLocations : 'pickupLocations/pickupLocations'
        }),
        formattedDate() {
            return this.localUseDate ? moment(this.localUseDate).format('YYYY-MM-DD  |  dddd') : '';
        },
        minRentDate() {
            return (new Date()).toISOString().substr(0, 10);
        },
        wantDateTimeFormatted() {
            return moment(this.wantDate).format('hh:mm a');
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadPickupLocations : 'pickupLocations/loadPickupLocations'
        }),
        async init() {
            this.localWantDate = moment(this.wantDate).format('YYYY-MM-DD');
            this.localWantDateTime = moment(this.wantDate).format('hh:mm a');
            
            if( _st.isNUE( this.pickupLocations ) )
                await this.loadPickupLocations();
        },
        setUseDate() {
            this.useDateMenu = false;

            let wDate = new Date(this.localUseDate);
            wDate.setDate(wDate.getDate() - 1);
            this.localWantDate = wDate.toISOString().substr(0, 10);

            let rDate = new Date(this.localUseDate);
            rDate.setDate(rDate.getDate() + 1);

            if( moment(rDate.toISOString().substr(0, 10)).day() == 0 )
                rDate.setDate(rDate.getDate() + 1);

            this.localReturnDate = rDate.toISOString().substr(0, 10);
        },
        change() {
            this.$emit('change', {
                useDate         : this.localUseDate,
                wantDate        : this.localWantDate,
                wantDateTime    : this.localWantDateTime,
                returnDate      : this.localReturnDate,
                pickupLocation  : this.localPickupLocation,
                eventRoleId     : this.localEventRoleId,
                comments        : this.localComments
            });
        },
        validate() {
            let errorFound = false;
            this.useDateError = this.wantDateError = this.returnDateError = 
            this.wantDateTimeError = this.pickupLocationError = this.eventRoleError = '';

            if( _st.isNUE( this.localUseDate ) ) {
                this.useDateError = 'required';
                errorFound = true;
            }

            if( _st.isNUE( this.localWantDate ) ) {
                this.wantDateError = 'required';
                errorFound = true;
            }
            else if( (new Date(this.localWantDate)) > (new Date(this.localUseDate)) ) {
                this.wantDateError = 'must be before use date';
                errorFound = true;
            }
            else if( (new Date(this.localWantDate)) > (new Date(this.localReturnDate)) ) {
                this.wantDateError = 'must be before return date';
                errorFound = true;
            }

            if( _st.isNUE( this.localReturnDate ) ) {
                this.returnDateError = 'required';
                errorFound = true;
            }
            else if( (new Date(this.localReturnDate)) < (new Date(this.localUseDate)) ) {
                this.returnDateError = 'must be after use date';
                errorFound = true;
            }
            else if( (new Date(this.localReturnDate)) < (new Date(this.localWantDate)) ) {
                this.returnDateError = 'must be after pickup date';
                errorFound = true;
            }

            if( _st.isNUE( this.localWantDateTime ) ) {
                this.wantDateTimeError = 'required';
                errorFound = true;
            }
            else if ( parseInt(this.localWantDateTime.split(':')[0]) < 8 || parseInt(this.localWantDateTime.split(':')[0]) >= 18 ) {
                this.wantDateTimeError = 'invalid';
                errorFound = true;
            }
            else if( parseInt(this.localWantDateTime.split(':')[1]) < 0 || parseInt(this.localWantDateTime.split(':')[1]) > 59 ) {
                this.wantDateTimeError = 'invalid';
                errorFound = true;
            }

            if( _st.isNUE( this.localPickupLocation ) ) {
                this.pickupLocationError = 'required';
                errorFound = true;
            }

            if( _st.isNUE( this.localEventRoleId ) ) {
                this.eventRoleError = 'required';
                errorFound = true;
            }

            return !errorFound;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>